.wrapper {
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 100px;
}

.burgerMenuContainer {
	transition: all 0.5s;
	position: fixed;
	top: 58px;
	left: 0;
	width: 100%;
	height: 0;
	z-index: 160;
	overflow-y: hidden;
	align-items: flex-start;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	& > a {
		color: #1c1c1c;
	}
}

.menuWrapper {
	margin-top: 1rem;
}

.is_active {
	height: 100%;
	background-color: white;

	display: flex;
	align-items: flex-start;
	transition: all 0.5s;
	backdrop-filter: blur(10px);
	background: rgba(241, 241, 241, 0.3);
}

.menuburgerButoonBox {
	max-width: 370px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 49px;

	background-color: #fcb53b;
}

@media (min-width: 996px) {
	.wrapper {
		display: none;
	}

	.burgerMenuContainer {
		display: none;
	}
}

@media (max-width: 576px) {
	.burgerMenuContainer {
		margin-top: 0px;
	}
}

.item {
	color: #000000;
	transition-duration: 3s;
	visibility: visible;
	transition: all 0.3s;
}

.itemBurger {
	margin-bottom: 10px;
	text-align: center;
	color: #000000;
	transition: all 0.3s;
	font-size: 1rem;

	&:hover {
		color: #fcb53b;
	}
}

.box {
	height: max-content;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: #ffffff;
	transition: all 0.5s;
	padding-bottom: 1rem;
}

.linkAnimation {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.1s;
	transition-delay: 0.1s;
}

.linkAnimationActive {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.1s;
	transition-delay: 0.1s;
}

.linkAnimation1 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.15s;
	transition-delay: 0.15s;
}

.linkAnimationActive1 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.15s;
	transition-delay: 0.15s;
}

.linkAnimation2 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.2s;
	transition-delay: 0.2s;
}

.linkAnimationActive2 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.2s;
	transition-delay: 0.2s;
}

.linkAnimation3 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.25s;
	transition-delay: 0.25s;
}

.linkAnimationActive3 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.25s;
	transition-delay: 0.25s;
}

.linkAnimation4 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.3s;
	transition-delay: 0.3s;
}

.linkAnimationActive4 {
	opacity: 1;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	transition: all 0.3s;
	transition-delay: 0.3s;
}

.linkAnimation5 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.35s;
	transition-delay: 0.35s;
}

.linkAnimationActive5 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.35s;
	transition-delay: 0.35s;
}

.linkAnimation6 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.4s;
	transition-delay: 0.4s;
}

.linkAnimationActive6 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.4s;
	transition-delay: 0.4s;
}

.linkAnimation7 {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.4s;
	transition-delay: 0.4s;
}

.linkAnimationActive7 {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.4s;
	transition-delay: 0.4s;
}

.linkAnimationButton {
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	transition: all 0.45s;
	transition-delay: 0.45s;

	max-width: 370px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 49px;

	background-color: #fcb53b;
}

.linkAnimationActiveButton {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	transition: all 0.45s;
	transition-delay: 0.45s;

	max-width: 370px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 49px;

	background-color: #fcb53b;
}

.hidden {
	display: none;
}

/* Visible state */
.visible {
	display: block;
}

.mdContent {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0rem 1rem 0.5rem 1rem;
	margin-bottom: 0.5rem;
	box-shadow: 0 10px 10px rgb(0 0 0 / 10%);

	& > a {
		margin-bottom: 0.5rem;
	}
	& > a:hover {
		color: #fcb53b;
	}
}
